.sis-slide {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.sis-slide-fadein {
  -o-transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

.sis-visible-slide {
  opacity: 1;
  z-index: 7000;
}

.sis-hidden-slide {
  opacity: 0;
  z-index: 0;
}

.sis-background {
  z-index: 0;
  background-color: #000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.sis-slide-info {
  opacity: .95;
  z-index: 9000;
  background-color: #80b4c1;
  position: fixed;
  top: 0;
  left: 0;
}

.sis-slide-info-next-slide {
  z-index: 10000;
  background-color: #90ee90;
  width: 105px;
  padding: 3px;
  font-size: 14px;
  position: fixed;
  top: 50px;
  left: 0;
}

.sis-slide-info-next-update {
  z-index: 10000;
  background-color: #f08080;
  width: 105px;
  padding: 3px;
  font-size: 14px;
  position: fixed;
  top: 70px;
  left: 0;
}

.sis-infobanner {
  z-index: 8000;
  width: 100%;
  position: fixed;
  overflow: hidden;
}

.sis-infobanner-inner {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  padding: 10px;
}

.sis-infobanner-inner p {
  margin: 0;
  padding: 0;
}

.sis-infobanner-bottom {
  bottom: 0;
}

.sis-infobanner-full {
  height: 100%;
  max-height: 100%;
}

.sis-infobanner-top {
  top: 0;
}

.sis-infobanner-hidden {
  opacity: 0;
}

.sis-night-mode {
  z-index: 1;
  background-color: #000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.sis-night-mode img {
  position: fixed;
}

.sis-iframe {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  border: 0;
}

.sis-error-no-data {
  color: red;
  text-align: center;
  z-index: 1;
  background-color: #000;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  font-size: 18pt;
  position: fixed;
  top: 0;
  left: 0;
}

.sis-error-toast {
  color: #fff;
  text-align: center;
  z-index: 9999;
  background-color: red;
  border-radius: 5px;
  width: 480px;
  height: 30px;
  padding-top: 5px;
  font-size: 16pt;
  position: fixed;
  top: 10px;
  left: calc(50% - 240px);
}

.sis-hidden {
  opacity: 0;
  z-index: 0;
  pointer-events: none;
}
/*# sourceMappingURL=index.c4e16db9.css.map */
