.sis-slide {
  position: fixed;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;
  opacity: 0;
}

.sis-slide-fadein {
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

.sis-visible-slide {
  opacity: 1;
  z-index: 7000;
}

.sis-hidden-slide {
  opacity: 0;
  z-index: 0;
}

.sis-background {
  position: fixed;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;
  background-color: black;
  z-index: 0;
}

.sis-slide-info {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.95;
  background-color: #80b4c1;
  z-index: 9000;
}

.sis-slide-info-next-slide {
  font-size: 14px;
  position: fixed;
  top: 50px;
  left: 0;
  width: 105px;
  padding: 3px;
  z-index: 10000;
  background-color: lightgreen;
}

.sis-slide-info-next-update {
  font-size: 14px;
  position: fixed;
  top: 70px;
  left: 0;
  width: 105px;
  padding: 3px;
  z-index: 10000;
  background-color: lightcoral;
}

.sis-infobanner {
  position: fixed;
  width: 100%;
  z-index: 8000;
  overflow: hidden;
}

.sis-infobanner-inner {
  transform-origin: 0 0;
  padding: 10px;

  p {
    margin: 0;
    padding: 0;
  }
}

.sis-infobanner-bottom {
  bottom: 0;
}

.sis-infobanner-full {
  height: 100%;
  max-height: 100%;
}

.sis-infobanner-top {
  top: 0;
}

.sis-infobanner-hidden {
  opacity: 0;
}

.sis-night-mode {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  z-index: 1;

  img {
    position: fixed;
  }
}

.sis-iframe {
  border: 0;
  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.sis-error-no-data {
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 10px;
  height: 100%;
  width: 100%;
  background-color: black;
  color: red;
  font-size: 18pt;
  text-align: center;

  z-index: 1;
}

.sis-error-toast {
  position: fixed;
  top: 10px;
  left: calc(50% - 240px);

  height: 30px;
  width: 480px;
  background-color: red;
  color: white;
  font-size: 16pt;
  text-align: center;
  border-radius: 5px;
  padding-top: 5px;

  z-index: 9999;
}

.sis-hidden {
  opacity: 0;
  z-index: 0;
  pointer-events: none;
}
